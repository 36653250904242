/* Dropdown Button */
.dropbtn {
    background-color: #fff;
    color: white;
    padding: 7px;
    padding-bottom: 6px;
    align-self: center;
    font-size: 16px;
    border: none;
    display: flex;
}

/* The container <div> - needed to position the dropdown content */
/* .dropdown {
    left: -50px;
    position: relative;
    display: inline-block;
} */

.statusText {
    color: #fff;
    font-size: 12px;
    font-weight: 600;
}

.operatorStatus {
    border:1px solid transparent;
    cursor: pointer;
}

.operatorStatus_online {
    padding: 2px 10px;
    background-color: #4dd965;
    border-radius: 10px;
}

.operatorStatus_on-break {
    padding: 2px 10px;
    background-color: #ffc107;
    border-radius: 10px;
}

.operatorStatus_offline {
    padding: 2px 10px;
    background-color: #f44336;
    border-radius: 10px;
}

.operatorStatusIcon_online {
    max-width: 6px;
    max-height: 6px;
    margin-right: 10px;
    border-radius: 50%;
    background-color: #4dd965;
}

.operatorStatusIcon_on-break {
    max-width: 6px;
    max-height: 6px;
    margin-right: 10px;
    border-radius: 50%;
    background-color: #ffc107;
}

.operatorStatusIcon_offline {
    max-width: 6px;
    max-height: 6px;
    margin-right: 10px;
    border-radius: 50%;
    background-color: #f44336;
}

/* Dropdown Content (Hidden by Default) */
.dropdown_content {
    display: none;
    position: absolute;
    top: 40px;
    background-color: #f1f1f1;
    border-radius: 5px;
    min-width: 220px;
    width: 100%;
    box-shadow: 0px 10px 18px 0px rgba(0, 0, 0, 0.3);
    z-index: 1;
}

/* Links inside the dropdown */
.dropdown_content div {
    color: black;
    padding: 12px 12px;
    text-decoration: none;
    display: block;
}

/* Change color of dropdown links on hover */
.dropdown_content div:hover {
    background-color: #ddd;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown_content {
    display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
    /* background-color: #3e8e41; */
}

.left {
    height: 45px;
}

.left_container {
    display: none;
}
.left span {
    align-self: flex-start;
    width: 100px;
    flex-direction: column;
    padding: 5px;
}

.left:hover .left_container {
    display: flex;
    width: 110px;
    height: 110px;
    background-color: #fff;
    position: relative;
    left: -121px;
    top: -32px;
    margin: 0px;
    box-shadow: 0px 10px 18px 0px rgba(0, 0, 0, 0.3);
    align-items: center;
    border-radius: 5px;
    justify-content: space-between;
}

.left span:hover {
    background-color: #ddd;
    border-radius: 5px;
    transform: scale(1.02);
}
