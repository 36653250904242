.overlay {
    position: absolute;
    top: 0;
    z-index: 1000;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.modal {
    position: absolute;
    z-index: 1001;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    width: 413px;
    height: auto;
}

.header {
    padding: 0px 20px;
    background-color: #00aaf6;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logoWrapper {
    display: flex;
    align-items: center;
}

.logoText {
    margin-left: 5px;
    font-size: 16px;
    color: #fff;
}

.dateInfo {
    color: #fff;
    font-size: 12px;
}

.body {
    padding: 20px 20px 15px;
}

.title {
    font-size: 16px;
    font-weight: 600;
    color: #000;
    margin-bottom: 12px;
}

.text {
    font-size: 14px;
    color: #000;
}

.date {
    font-weight: 600;
}

.comment {
    margin-top: 20px;
}

.commentText {
    opacity: 0.54;
}

.btn {
    margin-left: auto;
    margin-top: 16px;
    background-color: #00aaf6;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 145px;
    height: 36px;
    padding: 11px 30px;
    border-radius: 4px;
    outline: none;
    border: none;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.4s ease;
}

.btn:hover {
    opacity: 0.4;
}
